<template>
  <validation-provider
    #default="validationContext"
    :name="name"
    :rules="rules"
  >
    <b-form-group
      :label="name"
      label-for="description"
    >
      <quill-editor
        id="description"
        v-model="descriptionProxy"
        :options="editorOptions"
        :state="validate(validationContext)"
        :disabled="disabled"
      />
      <b-form-invalid-feedback :state="validate(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="text-right">
      <span class="text-primary">{{ descriptionProxy?descriptionProxy.length:0 }} chars</span>
    </div>
  </validation-provider>
</template>
<script>
import { quillEditor, Quill } from 'vue-quill-editor'

export default {
  name: 'RichTextEditor',
  components: { quillEditor },
  props: {
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    description: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction
              [{ color: ['black', 'blue', 'orange'] }], // dropdown with defaults from theme
              [{ size: [false, '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
              [{ align: [] }],
              ['clean'], // remove formatting button
            ],
          },
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    descriptionProxy: {
      get() {
        return this.description
      },
      set(value) {
        this.$emit('update:description', value)
      },
    },
  },
  mounted() {
    const SizeStyle = Quill.import('attributors/style/size')
    SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px']
    Quill.register(SizeStyle, true)

    const direction = Quill.import('attributors/style/direction')
    Quill.register(direction, true)

    const align = Quill.import('attributors/style/align')
    Quill.register(align, true)
  },
  methods: {
    validate(validationContext) {
      return validationContext.dirty || validationContext.validated ? validationContext.valid : null
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
